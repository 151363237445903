var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"appContainer"},[_c('Header',{style:({ 'padding-top': (_vm.headerHeight + "px") })}),_c('Hero',{attrs:{"disable-tenant":"","image":"home.png","title":"Unlock life with a quick and easy loan","leading":"From big purchases to holidays and home improvements,\n      a loan unlocks so many possibilities - and the money could be\n      with you very soon","points":[
        'Simple, easy application',
        'Rates from 5.99% and exclusive offers',
        '5-star customer service',
        'No impact on your credit score',
        'No upfront fees or hidden charges'
        ]}}),_vm._m(0),_c('TrustPilot'),_c('Hero',{attrs:{"column-class":"col-12 col-md-7 col-lg-6 col-xl-5 py-5","image":"home-app.png","title":"Customer friendly technology"}},[_c('p',[_vm._v("Our customers are at the heart of everything we do – a fact supported by our thousands of five-star customer reviews across all brands.")]),_c('p',[_vm._v("By empowering consumers to interact with us on multiple channels – including customer portals, secure apps and bots – we give them choice and control, along with the reassurance of support via our advisors and agents.")])]),_c('InfoSection',{attrs:{"image":"searching","title":"We work hard - you get the best deal."}},[_c('p',{staticClass:"mt-3"},[_vm._v("Because we’re a credit broker, not a lender, we’re able to search a panel of leading lenders to find the loan that suits you best.")]),_c('p',[_vm._v("Got a less-than-perfect credit history? No need to worry.")]),_c('p',[_vm._v("Got CCJs or defaults? The chances are, we’ll still be able to help.")]),_c('p',[_vm._v("Hundreds of products, loads of options, our technology cuts through the noise and our experts will advise on the most suitable option for you.")]),_c('RepExample')],1),_c('Hero',{attrs:{"column-class":"col-12 col-md-7 offset-md-5 col-lg-6 offset-lg-6 col-xl-5 offset-xl-7 py-2","background-style":"background-position: 65% center;","image":"home-hassle-free.png","title":"No hassles or headaches.","leading":"We've developed our own advanced technology platform\n      to help get your quote quickly - and your money could be with\n      you in just ten days.","points":[
        'Loans from £5,000 to £500,000',
        'Interest Rates From Only 5.99%',
        'All credit profiles considered',
        'As a leading Credit Broker and not a lender we’ve more offers and options',
        'Real rates tailored just for you',
        'Fast secure online process you control!' ],"bullet":"circle","no-form":""}}),_c('HowItWorks'),_c('Contact'),_c('Lenders'),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container pt-0 pt-sm-3 pt-md-4 pt-lg-5"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-6 offset-3 col-sm-3 offset-sm-0"},[_c('img',{staticClass:"img-fluid mb-3 mb-sm-0",attrs:{"src":require("@/assets/mf-finalist-logo-2024-broker-rgb.png"),"alt":"Moneyfacts Awards 2024 Finalist"}})]),_c('div',{staticClass:"col-12 col-sm-9"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/MSA-2022-Winner.png"),"alt":"Mortgage Strategy Awards, Best Second Charge Broker, Winner 2022"}})])])])}]

export { render, staticRenderFns }