var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"appContainer"},[_c('Header',{style:({ 'padding-top': (_vm.headerHeight + "px") })}),_c('Hero',{attrs:{"disable-tenant":"","image":"decorate.png","title":"Get houseproud with a home improvement loan.","leading":"A home improvement loan is quick and simple to arrange, so\n      you’ll soon be able to give your home a new lease of life.","points":[
        'Simple, stress-free application process',
        'Get your hands on your money fast',
        'Competitive rates from our panel of lenders',
        '5-star customer service',
        'No upfront fees or hidden charges'
        ]}}),_c('TrustPilot'),_c('InfoSection',{attrs:{"image":"home.png","title":"No hassles or headaches.","leading":"A home improvement loan could transform the place you live. From ﬁnding\n      your competitive quote to completing our simple, straightforward application\n      process, this is home improvement without hassles.","points":[
        'Homeowner Loans from £5,000 to £500,000',
        'Interest Rates From Only 5.99%',
        'All credit profiles considered',
        'Use your equity to make those improvements you’ve always wanted',
        'Fixed and variable terms available',
        'Improve your home and increase your house value!'
      ],"counter":true,"swap":true}}),_c('InfoSection',{attrs:{"image":"searching","title":"We work hard - you get the best deal.","leading":"Because we’re a credit broker, not a lender, we’re able\n      to search a panel of leading lenders to find the loan that suits you best."}},[_c('p',[_vm._v("Got a less-than-perfect credit history? No need to worry.")]),_c('p',[_vm._v("Got CCJs or defaults? The chances are, we’ll still be able to help.")]),_c('p',[_vm._v("Hundreds of products, loads of options, our technology cuts through the noise and our experts will advise on most the suitable option for you.")]),_c('RepExample')],1),_c('HowItWorks'),_c('Contact'),_c('Lenders'),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }