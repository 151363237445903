<template>
    <div ref="appContainer">
      <Header :style="{ 'padding-top': `${headerHeight}px` }"></Header>
      <Hero
          disable-tenant
          image="debt.png"
          title="It’s easy to do debt better."
          leading="Every day, Believe helps people just like you to put debt
          worries behind them with an affordable secured loan. "
          :points="[
            'Solutions for any credit history',
            'No impact on your credit score',
            'No upfront fees',
            'Consolidate your debts',
            'Reduce outgoings – one simple monthly payment'
            ]"
      />
      <TrustPilot />
      <InfoSection
          image="celebrate.png"
          title="No hassles or headaches."
          leading="By using a loan to pay off your existing debts – imagine
          that! – you get a single, manageable monthly payment and a life
          without all that worry and stress."
          :points="[
            'Loans from £5,000 to £500,000',
            'Interest Rates From Only 5.99%',
            'All credit profiles considered',
            'As a leading Credit Broker and not a lender we search a panel of lenders',
            'Pay off credit and store cards and loan debts into one affordable payment',
            'Simple, stress-free application process you control!'
          ]"
          :counter="true"
          :swap="true"
      />
      <InfoSection
          image="searching"
          title="We work hard - you get the best deal."
          leading="Because we’re a credit broker, not a lender, we’re able
          to search a panel of leading lenders to find the loan that suits you best."
      >
        <p>Got a less-than-perfect credit history? No need to worry.</p>
        <p>Got CCJs or defaults? The chances are, we’ll still be able to help.</p>
        <p>Hundreds of products, loads of options, our technology cuts through the noise and our experts
          will advise on the most suitable option for you.</p>
        <RepExample />
      </InfoSection>
      <HowItWorks/>
      <Contact />
      <Lenders />
      <Footer />
    </div>
</template>

<script>
import { Bus } from '../bus'

import Header from '../Sections/Header.vue'
import Hero from '../Sections/Hero.vue'
import TrustPilot from '../Sections/TrustPilot.vue'
import InfoSection from "../Sections/InfoSection";
import Contact from '../Sections/Contact.vue'
import Lenders from '../Sections/Lenders.vue'
import Footer from '../Sections/Footer.vue'
import HowItWorks from "../Sections/HowItWorks";
import RepExample from "../components/RepExample";

export default {
  name: 'app',
  components: {
    HowItWorks,
    InfoSection,
    Header,
    Hero,
    TrustPilot,
    Contact,
    Lenders,
    Footer,
    RepExample
  },
  data() {
    return {
      height: 0
    }
  },
  computed: {
    headerHeight() {
      return this.height;
    }
  },
  created() {
    Bus.$on("headerHeight", (height) => {
      this.height = height;
    });
  },
  mounted() {
    window.addEventListener("message", event => {
      if (event.data.type === "scroll") {
        const iframePosition = document.getElementsByTagName('iframe')[0].getBoundingClientRect();
        event.data.offset -= this.height;
        if (event.data.offset < 0) {
          window.scrollTo(iframePosition.left, this.height);
          return;
        }
        const newScrollY =
          iframePosition.top + event.data.offset + window.scrollY;
        if (newScrollY < window.scrollY) {
          window.scrollTo(iframePosition.left, newScrollY);
        }
      }
    });
  }
}
</script>
